import { Component, ViewChild } from '@angular/core';
import { FieldFormatEnum, FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UiCommand, UiCommandEvent } from '../../cer/cer-grid/cer-grid-command.service';

// WebApi
import { SalesChannelClient, SalesChannelSyncCommand } from "../api";
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerFormPanesSettings, uiCmdBottomToggle } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'salestable',
  templateUrl: './salestable.component.html',
  styleUrls: ['../component.css']
})

export class SalesTableComponent {
  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  protected panes: CerFormPanesSettings = {
    main: { visible: 'show', size: '75%', sizeSecondary: '75%' },
    right: { visible: 'collapse', size: '75%' },
    bottom: { visible: 'show', size: '25%' },
    orientationPrimary: 'vertical'
  };

  tableGrid: CerGridComponent;
  @ViewChild('tableGrid', { static: false }) set tableGridContent(content: CerGridComponent) {
    if (content && !this.tableGrid) {
      this.tableGrid = content;
      this.tableGrid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  lineGrid: CerGridComponent;
  @ViewChild('lineGrid', { static: false }) set lineGridContent(content: CerGridComponent) {
    if (content && !this.lineGrid) {
      this.lineGrid = content;
    }
  }

  // View
  public tableViewMetadata: ViewMetadata = {
    name: 'sales-table', dataApiName: 'SalesTable', text: 'Salgsordrer',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Fields
  public tableFieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
      { name: 'num', text: 'Ordrenr.', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S },
      { name: 'salesChannelId', text: 'Salgskanal', tooltipText: 'Kanal', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesChannel' },
      { name: 'salesChannelSalesNum', text: 'Ordre (kanal)', tooltipText: 'Kanalordre', widthType: FieldWidthEnum.M },
      { name: 'amountCur', text: 'Fakturabeløb', allowEdit: true, format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.M },
      { name: 'amountCurTax', text: 'Momsbeløb', allowEdit: true, format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.M },
      { name: 'amountCurExclTax', text: 'Fakturabeløb u. moms', allowEdit: true, format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.M },
      { name: 'currency', text: 'Valuta', widthType: FieldWidthEnum.S },
      { name: 'custType', text: 'Kundetype', widthType: FieldWidthEnum.S },
      { name: 'paymentType', text: 'Betalingsmetode', tooltipText: 'Betalingsmetode', allowEdit: true, widthType: FieldWidthEnum.S },
      { name: 'paymentTransaction', text: 'Indløser trans.', tooltipText: 'Betalingsindløser transaktion', allowEdit: true, widthType: FieldWidthEnum.M },
      { name: 'paymentReference', text: 'Indløser ref.', tooltipText: 'Betalingsindløser ref.', allowEdit: true, widthType: FieldWidthEnum.S, visible: false },
      { name: 'paymentMethod', text: 'Betalingsmetode kode', tooltipText: 'Betalingsmetode kode', allowEdit: true, widthType: FieldWidthEnum.S, visible: false },
      { name: 'paymentCaptured', text: 'Indløst', tooltipText: 'Er betaling indløst?', allowEdit: true, format: FieldFormatEnum.CheckBox },
      { name: 'settlementType', text: 'Afregningstype', tooltipText: 'Intern afregningstype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesSettlementType', foreignKeyField: 'num' },
      { name: 'name', text: 'Navn', tooltipText: 'Navn', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
      { name: 'billFirstName', text: 'Fak. fornavn', tooltipText: 'Faktura fornavn', widthType: FieldWidthEnum.L, visible: false},
      { name: 'billLastName', text: 'Fak. efternavn', tooltipText: 'Faktura efternavn', widthType: FieldWidthEnum.L, visible: false },
      { name: 'billCompanyName', text: 'Fak. firmanavn', tooltipText: 'Faktura firmanavn', widthType: FieldWidthEnum.L },
      { name: 'custEmail', text: 'E-mail', tooltipText: 'Kunde e-mail', format: FieldFormatEnum.Email },
      { name: 'custPhone', text: 'Telefon', tooltipText: 'Kunde telefon', format: FieldFormatEnum.Phone },
      { name: 'custNum', text: 'Kundenr.', tooltipText: 'Kundenr.', widthType: FieldWidthEnum.M },
      { name: 'custEan', text: 'EAN nr.', widthType: FieldWidthEnum.M },
      { name: 'custVatNum', text: 'CVR nr.', tooltipText: 'Kundens CVR nummer', widthType: FieldWidthEnum.M },
      { name: 'custVatExempt', text: 'Momsfri', tooltipText: 'Er ordren momsfri?', format: FieldFormatEnum.CheckBox },
      { name: 'billAddress1', text: 'Fak. adresse 1', tooltipText: 'Faktura adresse 1', widthType: FieldWidthEnum.L },
      { name: 'billAddress2', text: 'Fak. adresse 2', tooltipText: 'Faktura adresse 2', widthType: FieldWidthEnum.L },
      { name: 'billPostalCode', text: 'Fak. postnr.', tooltipText: 'Faktura postnr.', widthType: FieldWidthEnum.S },
      { name: 'billPostalDistrict', text: 'Fak. by', tooltipText: 'Faktura by', widthType: FieldWidthEnum.L },
      { name: 'billCountryCode', text: 'Fak. land', tooltipText: 'Faktura landekode', widthType: FieldWidthEnum.S },
      { name: 'shipToInvoiceAddress', text: 'Lev. fak.adresse', tooltipText: 'Levering til fakturaadresse', format: FieldFormatEnum.CheckBox },
      { name: 'shipFirstName', text: 'Lev. fornavn', tooltipText: 'Levering fornavn', widthType: FieldWidthEnum.L, visible: false },
      { name: 'shipLastName', text: 'Lev. efternavn', tooltipText: 'Levering efternavn', widthType: FieldWidthEnum.L, visible: false },
      { name: 'shipCompanyName', text: 'Lev. firmanavn', tooltipText: 'Leveringfirmanavn', widthType: FieldWidthEnum.L, visible: false },
      { name: 'shipAddress1', text: 'Lev. adresse 1', tooltipText: 'Levering adresse 1', widthType: FieldWidthEnum.L, visible: false },
      { name: 'shipAddress2', text: 'Lev. adresse 2', tooltipText: 'Levering adresse 2', widthType: FieldWidthEnum.L, visible: false },
      { name: 'shipPostalCode', text: 'Lev. postnr.', tooltipText: 'Levering postnr.', widthType: FieldWidthEnum.S, visible: false },
      { name: 'shipPostalDistrict', text: 'Lev. by', tooltipText: 'Levering by', widthType: FieldWidthEnum.L, visible: false },
      { name: 'shipCountryCode', text: 'Lev. land', tooltipText: 'Levering landekode', widthType: FieldWidthEnum.S, visible: false }

      /*
          ,[DateCreated]
          ,[DateCompleted]
          ,[DateModified]
          ,[Created]
          ,[CreatedBy]
          ,[Modified]
          ,[ModifiedBy]    */
    ];


  public tableFieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'salesChannelId', 'num', 'salesChannelSalesNum'], width: 240 },
    { idx: 2, tabIdx: 0, name: 'customer', text: 'Kunde', fields: ['name', 'custEmail', 'custPhone', 'custType', 'custNum', 'custEan', 'custVatNum', 'custVatExempt'] },
    { idx: 3, tabIdx: 0, name: 'amounts', text: 'Beløb', fields: ['amountCurExclTax', 'amountCurTax', 'amountCur', 'currency'] },
    { idx: 3, tabIdx: 0, name: 'settlement', text: 'Afregning', fields: ['settlementType', 'paymentType', 'paymentMethod', 'paymentTransaction', 'paymentReference', 'closed', 'paymentCaptured'] },
    { idx: 4, tabIdx: 0, name: 'shipment', text: 'Levering', fields: ['shipFirstName', 'shipLastName', 'shipCompanyName', 'shipAddress1', 'shipAddress2', 'shipPostalCode', 'shipPostalDistrict', 'shipCountryCode', 'shipToInvoiceAddress'] },
    { idx: 5, tabIdx: 0, name: 'bill', text: 'Faktura', fields: ['billFirstName', 'billLastName', 'billCompanyName', 'billAddress1', 'billAddress2', 'billPostalCode', 'billPostalDistrict', 'billCountryCode'] }
  ];

  // Toolbar
  private uiCmdSyncSalesOrders: UiCommand = { id: 'uiCmdSyncSalesOrders', text: 'Opdater salgskanaler', tooltipText: 'Hent ordrer fra aktivt salgskanaler', iconCss: 'e-menu-icon e-icons-org e-repeat' };
  private uiCmdBottomToggle = Object.assign(new Object(), uiCmdBottomToggle, { text: 'Linjer', tooltipText: 'Vis linjer' });

  public tableToolbarCommands: UiCommand[] = [this.uiCmdSyncSalesOrders, this.uiCmdBottomToggle];

  // TableMeta view
  public tableMetaViewMetadata: ViewMetadata = {
    name: 'sales-meta', dataApiName: 'SalesTableMeta', text: 'Ordre meta',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'salesTableId', titleFields: ['key'], allowEdit: true, allowCreate: true, allowDelete: true
  };

  // TableMeta fields
  public tableMetaFieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false, isIdentity: true, widthType: FieldWidthEnum.S },
    { name: 'salesTableId', text: 'Ordre', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S, visible: false },
    { name: 'key', text: 'Nøgle' },
    { name: 'value', text: 'Værdi' }
    ];


  // Detail view
  public lineViewMetadata: ViewMetadata = {
    name: 'sales-line', dataApiName: 'SalesLine', text: 'Ordre linjer',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'salesId', titleFields: ['itemId'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };

  // Detail  fields
  public lineFieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false, isIdentity: true, widthType: FieldWidthEnum.S },
    { name: 'num', text: 'Ordrenr.', visible: false },
    { name: 'salesId', text: 'Ordre ID', visible: false },
    { name: 'itemId', text: 'Varenr.', width: '100px' },
    { name: 'name', text: 'Varenavn', width: '160px' },
    { name: 'lineTypeId', text: 'Linjetype ID', visible: false, allowEdit: false },
    { name: 'salesQty', text: 'Antal', allowEdit: false, width: '80px', format: FieldFormatEnum.Amount },
    { name: 'deliveredQty', text: 'Antal leveret', width: '80px', format: FieldFormatEnum.Number },
    { name: 'invoicedQty', text: 'Antal faktureret', width: '80px', format: FieldFormatEnum.Number },
    { name: 'salesPrice', text: 'Salgspris', allowEdit: false, width: '80px', format: FieldFormatEnum.Amount },
    { name: 'salesDiscount', text: 'Rabat', allowEdit: false, width: '80px', format: FieldFormatEnum.Amount },
    { name: 'salesAmount', text: 'Beløb', format: FieldFormatEnum.Amount },
    { name: 'dateDelivered', text: 'Dato leveret', format: FieldFormatEnum.DateShort }
    ];



  constructor(private client: SalesChannelClient, private ui: CerAppDialogService) {
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case this.uiCmdSyncSalesOrders.id:
        this.syncSalesChannelAll();
        break;
    }
  }

  // Sync
  private syncSalesChannelAll() {
    var cmd = <SalesChannelSyncCommand>{ allSalesChannels: true, allEmailFetchers: true };
    this.client.post(cmd).subscribe({
      next: result => {
        this.tableGrid.research();
      },
      error: (error) => {
        this.ui.handleApiStatusMessage(error);
      }
    });
  }
}
