import { Component, ViewChild } from '@angular/core';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { FieldMetadata, ViewMetadata, FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { UiCommand, UiCommandEvent, UiKeyboardShortcut } from '../../../cer/cer-grid/cer-grid-command.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankAccountSourceSyncCommand, BankTransactionSourceBatchClient, BankSettlementStatusEnum, BankAccountTransactionDto, BankTransactionStatusSetCommand, BankAccountTransactionVoucherCreateCommand, VoucherMessageViewDetailsClient, VoucherMessageViewDetailsVm, BankAccountSettlementClient, BankTransactionVoucherCombineSelectedCommand, BankTransactionSuggestSettlementCommand, BankAccountTransactionClient, ChatDto, VoucherMessageStatusEnum, VoucherMessageViewClient } from 'src/app/api';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { VoucherDetailComponent } from '../../voucher/voucher-detail/voucher-detail.component';
import { VoucherService } from 'src/app/voucher/voucher.service';
import { CerAppChatService } from 'src/cer-app/cer-app-chat/cer-app-chat.service';
import { CerAppDialogService, DialogInput } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { VoucherDataService } from 'src/app/voucher/voucher-data.service';
import { UserSessionService } from 'src/platform/app-user/user-session-service';
import { AppStateService } from 'src/app-core/app-state/app-state.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'bank-account-settlement',
  templateUrl: './bank-account-settlement.component.html',
  styleUrls: [],
  providers: [VoucherService]
})
export class BankAccountSettlementComponent {

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  protected panes: CerFormPanesSettings = {
    top: {
      visible: 'show',
      visibleWhenClose: 'collapse',
      size: '30%'
    },
    main: {
      visible: 'show',
      visibleWhenClose: 'collapse',
      size: '70%',
      sizeSecondary: '50%'
    },
    right: {
      visible: 'show',
      visibleWhenClose: 'collapse',
      size: '50%'
    },
    orientationPrimary: 'vertical'
  }

  selectedGrid: CerGridComponent;
  @ViewChild('selectedGrid', { static: false }) set selectedGridContent(content: CerGridComponent) {
    if (content && !this.selectedGrid) {
      this.selectedGrid = content;
      //this.selectedGrid.grid.allowRowDragAndDrop = true;
      //this.selectedGrid.grid.rowDropSettings = { targetID: 'detailGrid' };
      this.selectedGrid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      //this.grid.grid.allowRowDragAndDrop = true;
      //this.grid.grid.rowDropSettings = { targetID: 'detailGrid' };
      this.grid.commmand.subscribe(e => this.onCommand(e));
    }
  }
  detailGrid: CerGridComponent;
  @ViewChild('detailGrid', { static: false }) set gridContentDetail(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
      //this.detailGrid.grid.allowRowDragAndDrop = true;
      this.detailGrid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  private uiCmdVoucherCombine: UiCommand = { id: 'VoucherCombine', text: 'Kombiner', tooltipText: 'Kombiner bilag fra bankpostering (ALT+C)', iconCss: 'e-menu-icon e-icons-org e-table-update', target: '.e-content' };
  private uiCmdVoucherOpenForm: UiCommand = { id: 'VoucherOpenForm', text: 'Rediger bilag', tooltipText: 'Rediger bilag og kontering (ALT+B)', iconCss: 'e-menu-icon e-icons-org e-changes-track', target: '.e-content', needSelection: true };
  private uiCmdVoucherOpenFormMain: UiCommand = { id: 'VoucherOpenForm', text: 'Rediger bilag', tooltipText: 'Rediger bilag og kontering (ALT+B)', iconCss: 'e-menu-icon e-icons-org e-changes-track', target: '.e-content', needSelection: true };
  private uiCmdVoucherCreateFromBankSelected: UiCommand = { id: 'VoucherCreateFromBankSelected', text: 'Nyt bilag fra bank', tooltipText: 'Opret nyt bilag ud fra bankpostering  (ALT+CTRL+N)', iconCss: 'e-menu-icon e-icons-org e-copy-1', target: '.e-content', needSelection: true };
  private uiCmdVoucherCreateFromBankMain: UiCommand = { id: 'VoucherCreateFromBankMain', text: 'Nyt bilag fra bank', tooltipText: 'Opret nyt bilag ud fra bankpostering  (ALT+CTRL+N)', iconCss: 'e-menu-icon e-icons-org e-copy-1', target: '.e-content', needSelection: true };
  private uiCmdVoucherCreate: UiCommand = { id: 'VoucherCreate', text: 'Nyt bilag', tooltipText: 'Opret nyt tom bilag (ALT+N)', iconCss: 'e-menu-icon e-icons-org e-file-new', target: '.e-content' };

  private uiCmdSetStatusMarkedSuggest: UiCommand = { id: 'uiCmdSetStatusMarkedSuggest', text: 'Forslag', tooltipText: 'Søg forslag til markerede poster (ALT+S)', iconCss: 'e-menu-icon e-icons-org e-search-2', target: '.e-content' };
  private uiCmdSetStatusMarked: UiCommand = { id: 'SetStatusMarked', text: 'Vælg', tooltipText: 'Vælg de markerede poster (ALT+V)', iconCss: 'e-menu-icon e-icons-org e-changes-next', target: '.e-content', needSelection: true };
  private uiCmdSetStatusOpen: UiCommand = { id: 'SetStatusOpen', text: 'Åbn', tooltipText: 'Sæt status åbn for de markerede poster (ALT+B)', iconCss: 'e-menu-icon e-icons-org e-changes-previous', target: '.e-content', needSelection: true };
  private uiCmdSetStatusRequest: UiCommand = { id: 'SetStatusRequested', text: 'Anmod', tooltipText: 'Anmod om bilag for markeret (ALT+R)', iconCss: 'e-menu-icon e-icons-org e-comment-reopen', target: '.e-content', needSelection: true };
  private uiCmdSetStatusPostponed: UiCommand = { id: 'SetStatusPostponed', text: 'Udsæt', tooltipText: 'Udsæt markerede poster (ALT+U)', iconCss: 'e-menu-icon e-icons-org e-changes-reject', target: '.e-content', needSelection: true };
  private uiCmdSetStatusSettled: UiCommand = { id: 'SetStatusSettled', text: 'Afstem', tooltipText: "Sæt status 'Afstemt' for alle poster (ALT+A)", iconCss: 'e-menu-icon e-icons-org e-changes-accept', target: '.e-content', needSelection: true };
  private uiCmdSetStatusCreated: UiCommand = { id: 'SetStatusCreated', text: "Sæt status 'Oprettet'", tooltipText: "Sæt status 'Oprettet for bilag (ALT+O)", iconCss: 'e-menu-icon e-icons-org e-changes-reject', target: '.e-content', needSelection: true };
  private uiCmdSetStatusPendingApproval: UiCommand = { id: 'SetStatusPendingApproval', text: "Sæt status 'Til godkendelse'", tooltipText: 'Sæt status til godkendelse for (ALT+X)', iconCss: 'e-menu-icon e-icons-org e-comment-reopen', target: '.e-content', needSelection: true };
  private uiCmdSetStatusApproved: UiCommand = { id: 'SetStatusApproved', text: "Sæt status 'Godkendt'", tooltipText: "Sæt status 'Godkendt' for bilag (ALT+G)", iconCss: 'e-menu-icon e-icons-org e-changes-accept', target: '.e-content', needSelection: true };
  private uiCmdSetStatusRejected: UiCommand = { id: 'SetStatusRejected', text: "Sæt status 'Annulleret'", iconCss: "e-menu-icon e-icons-org e-circle-close-2", target: ".e-content", needSelection: true };

  private uiCmdSeparator: UiCommand = { id: 'Separator', text: null, separator: 'content' };

  private uiCmdVoucherCombineShortcut: UiKeyboardShortcut = { cmd: this.uiCmdVoucherCombine, code: 'KeyC', shift: false, ctrl: false, alt: true };

  private uiCmdVoucherOpenFormShortcut: UiKeyboardShortcut = { cmd: this.uiCmdVoucherOpenForm, code: 'KeyB', shift: false, ctrl: false, alt: true };
  private uiCmdVoucherCreateFromBankMainShortcut: UiKeyboardShortcut = { cmd: this.uiCmdVoucherCreateFromBankMain, code: 'KeyN', shift: false, ctrl: true, alt: true };
  private uiCmdVoucherCreateShortcut: UiKeyboardShortcut = { cmd: this.uiCmdVoucherCreate, code: 'KeyN', shift: false, ctrl: false, alt: true };

  private uiCmdSetStatusOpenShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetStatusOpen, code: 'KeyO', shift: false, ctrl: false, alt: true };
  private uiCmdSetStatusMarkedSuggestShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetStatusMarkedSuggest, code: 'KeyS', shift: false, ctrl: false, alt: true };
  private uiCmdSetStatusMarkedShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetStatusMarked, code: 'KeyV', shift: false, ctrl: false, alt: true };
  private uiCmdSetStatusPostponedShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetStatusPostponed, code: 'KeyU', shift: false, ctrl: false, alt: true };
  private uiCmdSetStatusSettledShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetStatusSettled, code: 'KeyA', shift: false, ctrl: false, alt: true };

  public viewMetadata: ViewMetadata;
  public fieldMetadata: FieldMetadata[] = [];
  public toolbarCommands: UiCommand[];
  public contextMenuCommands: UiCommand[];
  public keyboardShortcuts: UiKeyboardShortcut[];

  public detailViewMetadata: ViewMetadata;
  public detailFieldMetadata: FieldMetadata[] = [];
  public detailToolbarCommands: UiCommand[];
  public detailContextMenuCommands: UiCommand[];
  public detailKeyboardShortcuts: UiKeyboardShortcut[];

  public selectedViewMetadata: ViewMetadata;
  public selectedFieldMetadata: FieldMetadata[] = [];
  public selectedToolbarCommands: UiCommand[];
  public selectedContextMenuCommands: UiCommand[];
  public selectedKeyboardShortcuts: UiKeyboardShortcut[];

  constructor(private routeService: CerAppRouteService,
    private userSessionService: UserSessionService,
    private stateService: AppStateService,
    private transactionClient: BankAccountTransactionClient,
    private settlementClient: BankAccountSettlementClient,
    private voucherMessageViewClient: VoucherMessageViewClient,
    private batchClient: BankTransactionSourceBatchClient,
    private detailsClient: VoucherMessageViewDetailsClient,
    private voucherService: VoucherService,
    private chatService: CerAppChatService,
    private ui: CerAppDialogService,
    private dialogService: CerDialogService,
    private appDialogService: CerAppDialogService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {

    this.selectedGridInit();
    this.gridInit();
    this.detailsGridInit();
  }

  // Selected
  private selectedGridInit() {
    this.selectedViewMetadata = {
      name: 'bank-transaktion-valgte', dataApiName: 'BankAccountTransaction', text: 'Valgte',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false, baseFields: ['id', 'history']
    };

    var userId: number = this.userSessionService.userId();
    this.selectedFieldMetadata =
      [
        { name: 'sourceBatch', text: 'Batch', visible: false, orderBy: 'Ascending', orderByPriority: 1 },
        { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: 'BankAccount', widthType: FieldWidthEnum.XXXS, visible: false },
        { name: 'source', text: 'Kilde', foreignKeyTableName: 'BankAccountTransactionSource', widthType: FieldWidthEnum.XXXS, visible: true },
        { name: 'transDate', text: 'Dato', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.XXXS, orderBy: 'Descending', orderByPriority: 2 },
        { name: 'txt', text: 'Tekst', widthType: FieldWidthEnum.XXXL },
        { name: 'authReference', text: "Nota Indløser", widthType: FieldWidthEnum.XXS },
        { name: 'bankCreditCard', text: "Kreditkort", widthType: FieldWidthEnum.XS, foreignKeyTableName: 'BankCreditCard', visible: false },
        { name: 'user', text: 'Medarbejder', widthType: FieldWidthEnum.XXXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
        { name: 'amount', text: 'Beløb', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.XS, footerType: 'sum', footerTemplate: '<ng-template #footerTemplate let-data>Sum: {{data.sum}}</ng-template>' },
        { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXXS },
        { name: 'status', text: 'Status', foreignKeyTableName: 'BankAccountTransactionStatus', widthType: FieldWidthEnum.XXXS, filterOperator: 'equal', filterValue: BankSettlementStatusEnum.Selected, filterOnClient: true },
        { name: 'modifiedBy', text: 'Bruger', foreignKeyTableName: 'User', foreignKeyValue: 'shortName', widthType: FieldWidthEnum.XXXS, filterOperator: 'equal', filterValue: userId, filterOnClient: true }
      ];

    this.selectedToolbarCommands = [this.uiCmdSetStatusMarkedSuggest, this.uiCmdVoucherCreate, this.uiCmdVoucherCreateFromBankSelected, this.uiCmdSeparator, this.uiCmdVoucherCombine, this.uiCmdVoucherOpenForm, this.uiCmdSeparator, this.uiCmdSetStatusSettled, this.uiCmdSeparator, this.uiCmdSetStatusOpen, this.uiCmdSetStatusPostponed];
    this.selectedKeyboardShortcuts = [this.uiCmdSetStatusSettledShortcut, this.uiCmdSetStatusMarkedSuggestShortcut, this.uiCmdVoucherCombineShortcut, this.uiCmdVoucherCreateShortcut, this.uiCmdVoucherCreateFromBankMainShortcut, this.uiCmdVoucherOpenFormShortcut, this.uiCmdSetStatusOpenShortcut, this.uiCmdSetStatusPostponedShortcut];
    this.selectedContextMenuCommands = [this.uiCmdVoucherOpenForm, this.uiCmdSeparator, this.uiCmdSetStatusOpen, this.uiCmdSetStatusPostponed];
  }

  // Main (vouchers)
  private gridInit() {
    this.viewMetadata = {
      name: 'bank-bilag', dataApiName: 'BankAccountTransaction', text: 'Bilag',
      dataApiParams: ['$sourceGroupIdFilterNe=2'],
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false, baseFields: ['history']
    };

    this.fieldMetadata =
      [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', widthType: FieldWidthEnum.XXXS, visible: false },
      { name: 'sourceBatch', text: 'Batch', widthType: FieldWidthEnum.XXXS, visible: false },
      { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: 'BankAccount', widthType: FieldWidthEnum.XXXS, visible: false },
      { name: 'source', text: 'Kilde', foreignKeyTableName: 'BankAccountTransactionSource', widthType: FieldWidthEnum.XXXS, visible: false, filterOperator: 'equal', filterValue: '1', filterOnClient: true },
      { name: 'sourceId', text: 'Besked id', widthType: FieldWidthEnum.XXXS, visible: false },
      { name: 'transDate', text: 'Dato', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.XXXS, orderBy: 'Descending' },
      { name: 'txt', text: 'Tekst', widthType: FieldWidthEnum.XXXL },
      { name: 'authReference', text: "Nota Indløser", widthType: FieldWidthEnum.XXS },
      { name: 'user', text: 'Medarbejder', widthType: FieldWidthEnum.XXXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
      { name: 'amount', text: 'Beløb', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.XS, footerType: 'Custom', foooterFunction: this.selectedAmountSumFn },
      { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXXS },
      { name: 'status', text: 'Status', foreignKeyTableName: 'BankAccountTransactionStatus', widthType: FieldWidthEnum.XS, filterOperator: 'in', filterValue: [BankSettlementStatusEnum.Open, BankSettlementStatusEnum.Postponed, BankSettlementStatusEnum.Requested, BankSettlementStatusEnum.Created, BankSettlementStatusEnum.PendingApproval, BankSettlementStatusEnum.Approved], filterOnClient: true }
      ];

    this.toolbarCommands = [this.uiCmdSetStatusMarked, this.uiCmdSeparator, this.uiCmdVoucherOpenFormMain];
    this.keyboardShortcuts = [this.uiCmdVoucherOpenFormShortcut, , this.uiCmdSetStatusMarkedSuggestShortcut, this.uiCmdVoucherCreateShortcut, this.uiCmdSetStatusOpenShortcut, this.uiCmdSetStatusPostponedShortcut, this.uiCmdSetStatusMarkedShortcut];
    this.contextMenuCommands = [this.uiCmdSetStatusMarked, this.uiCmdSeparator, this.uiCmdVoucherOpenFormMain, this.uiCmdSeparator, this.uiCmdSetStatusOpen, this.uiCmdSetStatusPostponed, this.uiCmdSeparator, this.uiCmdSetStatusCreated, this.uiCmdSetStatusPendingApproval, this.uiCmdSetStatusApproved, this.uiCmdSetStatusRejected];
  }

  // Details (bank transactions)
  private detailsGridInit() {
    this.detailViewMetadata = {
      name: 'bank-transaktion', dataApiName: 'BankAccountTransaction', text: 'Bank',
      dataApiParams: ['$sourceGroupIdFilter=2', '$activeAccountFilter=1'],
      primaryKey: 'id', allowEdit: true, allowCreate: false, allowDelete: false, baseFields: ['history']
    };

    this.detailFieldMetadata =
      [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', widthType: FieldWidthEnum.XXXS, visible: false, orderBy: 'Descending' },
      { name: 'sourceBatch', text: 'Batch', widthType: FieldWidthEnum.XXXS, visible: false, allowEdit: false },
      { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: 'BankAccount', widthType: FieldWidthEnum.XXXS, visible: false, allowEdit: false },
      { name: 'source', text: 'Kilde', foreignKeyTableName: 'BankAccountTransactionSource', widthType: FieldWidthEnum.XXXS, visible: false, allowEdit: false },
      { name: 'transDate', text: 'Dato', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.XXXS, allowEdit: false },
      { name: 'txt', text: 'Tekst', widthType: FieldWidthEnum.XXXL },
      { name: 'authReference', text: "Nota Indløser", widthType: FieldWidthEnum.XXS },
      { name: 'bankCreditCard', text: "Kreditkort", widthType: FieldWidthEnum.XS, foreignKeyTableName: 'BankCreditCard', visible: false, allowEdit: false },
      { name: 'user', text: 'Medarbejder', widthType: FieldWidthEnum.XXXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName', visible: false, allowEdit: false },
      { name: 'amount', text: 'Beløb', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.XS, footerType: 'Custom', foooterFunction: this.selectedAmountSumFn, allowEdit: false },
      { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXXS, visible: false, allowEdit: false },
      { name: 'status', text: 'Status', foreignKeyTableName: 'BankAccountTransactionStatus', widthType: FieldWidthEnum.XXXS, filterOperator: 'in', filterValue: [BankSettlementStatusEnum.Open, BankSettlementStatusEnum.Postponed, BankSettlementStatusEnum.Requested], filterOnClient: true /*, allowEdit: false*/ }
      ];

    this.detailToolbarCommands = [this.uiCmdSetStatusMarked, this.uiCmdSeparator, this.uiCmdSetStatusRequest, this.uiCmdVoucherCreateFromBankMain];
    this.detailKeyboardShortcuts = [this.uiCmdSetStatusMarkedShortcut, this.uiCmdSetStatusMarkedSuggestShortcut, this.uiCmdVoucherCreateFromBankMainShortcut, this.uiCmdSetStatusOpenShortcut, this.uiCmdSetStatusPostponedShortcut, this.uiCmdSetStatusMarkedShortcut];
    this.detailContextMenuCommands = [this.uiCmdSetStatusMarked, this.uiCmdSeparator, this.uiCmdVoucherCreateFromBankMain, this.uiCmdSeparator, this.uiCmdSetStatusOpen, this.uiCmdSetStatusPostponed, this.uiCmdSetStatusRequest];
  }

  public selectedAmountSumFn(data: Object): number {
    var grid = <GridComponent><any>this;
    var sum: number = 0;
    grid.getSelectedRecords().forEach(r => {
      sum += (<BankAccountTransactionDto>r).amount;
    });
    return sum;
  }

  private enableToolbarItems(g: CerGridComponent) {
    if (g?.grid?.toolbarModule && this.selectedGrid?.gridService) {
      var rows: any[] = this.selectedGrid.grid.getCurrentViewRecords();
      var count = rows?.length ?? 0;
      var voucherCount = rows?.filter(t => t.sourceId != null)?.length ?? 0;
      var bankCount = count - voucherCount;

      g.grid.toolbarModule.enableItems([this.uiCmdSetStatusMarkedSuggest.id], count <= 1);
      g.grid.toolbarModule.enableItems([this.uiCmdVoucherCreate.id], voucherCount == 0);
      g.grid.toolbarModule.enableItems([this.uiCmdVoucherCreateFromBankSelected.id], (voucherCount == 0 && bankCount > 0));
      g.grid.toolbarModule.enableItems([this.uiCmdVoucherCombine.id], (voucherCount > 0 && bankCount > 0));
      g.grid.toolbarModule.enableItems([this.uiCmdVoucherOpenForm.id], true); //voucherCount > 0);

      var status = count > 0 ? rows[0].status : null;
      g.grid.toolbarModule.enableItems([this.uiCmdSetStatusOpen.id], this.voucherService.BankStatusCanChange(status, BankSettlementStatusEnum.Open));
      g.grid.toolbarModule.enableItems([this.uiCmdSetStatusPostponed.id], this.voucherService.BankStatusCanChange(status, BankSettlementStatusEnum.Open));
      g.grid.toolbarModule.enableItems([this.uiCmdSetStatusSettled.id], this.voucherService.BankStatusCanChange(status, BankSettlementStatusEnum.Settled));
      if (g == this.grid) {
        g.grid.toolbarModule.enableItems([this.uiCmdSetStatusCreated.id], this.voucherService.BankStatusCanChange(status, BankSettlementStatusEnum.Created));
        g.grid.toolbarModule.enableItems([this.uiCmdSetStatusPendingApproval.id], this.voucherService.BankStatusCanChange(status, BankSettlementStatusEnum.PendingApproval));
        g.grid.toolbarModule.enableItems([this.uiCmdSetStatusApproved.id], this.voucherService.BankStatusCanChange(status, BankSettlementStatusEnum.Approved));
      }
    }
  }

  public onCommand(event: UiCommandEvent) {
    if ((event.actionType == 'dataBound' || event.actionType == 'rowSelect' || event.actionType == 'rowDeselect' || event.actionType == 'refresh') && (event.source == 'actionComplete')) {
      var g: CerGridComponent = <CerGridComponent>event.appComponent;
      if (g) {
        g.grid.aggregateModule.refresh(event.args.data);
        this.enableToolbarItems(g);
      }
    }

    if (event.actionType == 'rowDoubleClick') {
      var appGrid = this.getEventAppGrid(event);
      if (appGrid != null) {
        if ([this.selectedGrid.id, this.grid.id].includes(appGrid.id)) {
          event.commandId = this.uiCmdVoucherOpenForm.id;
        }
      }
    }
    switch (event.commandId) {
      case this.uiCmdVoucherCombine.id:
        this.voucherCombine(event);
        break;
      case this.uiCmdSetStatusMarkedSuggest.id:
        this.setStatusMarkedSuggest(event);
        break;
      case this.uiCmdVoucherOpenForm.id:
        this.voucherOpenForm(event);
        break;
      case this.uiCmdVoucherCreate.id:
        this.voucherCreate(event);
        break;
      case this.uiCmdVoucherCreateFromBankSelected.id:
      case this.uiCmdVoucherCreateFromBankMain.id:
        this.voucherCreateFromBank(event);
        break;
      case this.uiCmdSetStatusOpen.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Open);
        break;
      case this.uiCmdSetStatusPostponed.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Postponed);
        break;
      case this.uiCmdSetStatusRequest.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Requested);
        break;
      case this.uiCmdSetStatusMarked.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Selected);
        break;
      case this.uiCmdSetStatusSettled.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Settled);
        break;
      case this.uiCmdSetStatusCreated.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Created);
        break;
      case this.uiCmdSetStatusPendingApproval.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.PendingApproval);
        break;
      case this.uiCmdSetStatusApproved.id:
        this.setStatusFromEvent(event, BankSettlementStatusEnum.Approved);
        break;
      case this.uiCmdSetStatusRejected.id:
        var msgId: number = this.getEventMsgId(event);
        this.apiVoucherMessageStatusSet(msgId, VoucherMessageStatusEnum.Rejected);
        break;
    }
  }

  private setStatusFromEvent(event: UiCommandEvent, status: BankSettlementStatusEnum) {
    if (event.appComponent !== null && event.appComponent instanceof CerGridComponent) {
      var appGrid: CerGridComponent = event.appComponent as CerGridComponent;
      this.setStatus(appGrid, status);
    }
  }

  private setStatus(appGrid: CerGridComponent, status: BankSettlementStatusEnum) {

    var transactionFirst: BankAccountTransactionDto = <BankAccountTransactionDto>appGrid.rowSelectedData();
    var ids: number[] = [];

    if (status == BankSettlementStatusEnum.Settled) {
      appGrid.gridService.grid.getCurrentViewRecords().forEach(t => ids.unshift((t as BankAccountTransactionDto).id));
    }
    else if (status == BankSettlementStatusEnum.Selected) {
      var mainIds: number[] = this.grid.selection.rowsSelectedId();
      var detailIds: number[] = this.detailGrid.selection.rowsSelectedId();
      ids = [...mainIds, ...detailIds];
    }
    else {
      ids = appGrid.selection.rowsSelectedId();
    }
    if (ids !== null && ids.length > 0) {
      var cmd = {
        transIdList: ids,
        status: status,
      } as BankTransactionStatusSetCommand;
      if (status == BankSettlementStatusEnum.Requested) {
        this.setStatusDialog(cmd, appGrid, status, transactionFirst);
      }
      else if (status == BankSettlementStatusEnum.PendingApproval) {
        this.setStatusPendingApprovalDialog(cmd, appGrid, status, transactionFirst);
      }
      else {
        this.setStatusApi(cmd, appGrid, status);
      }
    }
  }

  private setStatusApi(cmd: BankTransactionStatusSetCommand, appGrid: CerGridComponent, status: BankSettlementStatusEnum) {
    this.routeService.isLoading$.next(true);
    this.transactionClient.setTransactionStatus(cmd).subscribe(
      {
        next: (result: BankAccountTransactionDto[]) => {
          appGrid.research();
          if (appGrid == this.selectedGrid) {
            if (status != BankSettlementStatusEnum.Settled) {
              this.grid.research();
              this.detailGrid.research();
            }
          }
          else {
            this.selectedGrid.research();
          }
          this.routeService.isLoading$.next(false);
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          this.appDialogService.handleApiStatusMessage(error);
        }
      });
  }

  private setStatusMarkedSuggest(event: UiCommandEvent) {
    var cmd = { bankAccountId: 1 } as BankTransactionSuggestSettlementCommand;
    this.setStatusMarkedSuggestCmdApi(cmd);
  }

  private setStatusMarkedSuggestCmdApi(cmd: BankTransactionSuggestSettlementCommand) {
    this.routeService.isLoading$.next(true);
    this.settlementClient.suggestSettlement(cmd).subscribe({
      next: (ok) => {
        if (ok) {
          this.selectedGrid.research();
          this.grid.research();
          this.detailGrid.research();
        }
        this.routeService.isLoading$.next(false);
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    }
    );
  }

  public setStatusPendingApprovalDialog(cmd: BankTransactionStatusSetCommand, appGrid: CerGridComponent, status: BankSettlementStatusEnum, transaction: BankAccountTransactionDto) {
    var transId = cmd.transIdList[0];
    var inputs: DialogInput[] = [
      { label: 'Godkender', id: "userId", placeholder: 'Angiv godkender', valueNumber: transaction?.user, type: 'DropDownList', dataSource: this.stateService.getDataTableCache('User').data, fields: { text: 'shortName', value: 'id' }, element: null }
    ];
    this.ui.inputPrompt('', 'Angiv godkender', inputs).then(
      result => {
        if (result.ok) {
          var responsible = result.dialogInputs[0].ej2.value;
          if (responsible != 0) {
            cmd.voucherRequestResponsible = responsible;
            this.setStatusApi(cmd, appGrid, status);
          }
          else {
            this.dialogService.infoPrompt('Ingen godkender valgt', 'Annulleret');
          }
        }
      }
    );
  }

  public setStatusDialog(cmd: BankTransactionStatusSetCommand, appGrid: CerGridComponent, status: BankSettlementStatusEnum, transaction: BankAccountTransactionDto) {
    var trans = cmd.transIdList[0];
    var transTxt = transaction.txt.substring(0, 40);
    var inputs: DialogInput[] = [
      { label: 'Beskrivelse', id: "desc", placeholder: 'Angiv beskrivelse', value: transTxt, element: null, maxLen: 40 },
      { label: 'Modtager', id: "userId", placeholder: 'Angiv modtagende medarbejder', valueNumber: transaction.user, type: 'DropDownList', dataSource: this.stateService.getDataTableCache('User').data, fields: { text: 'shortName', value: 'id' }, element: null }
    ];
    this.ui.inputPrompt('', 'Angiv modtager', inputs).then(
      result => {
        if (result.ok) {
          var desc = result.dialogInputs[0].value;
          var responsible = result.dialogInputs[1].ej2.value;
          if (responsible != 0) {
            cmd.voucherRequestResponsible = responsible;
            cmd.voucherRequestDescription = desc;
            this.setStatusApi(cmd, appGrid, status);
          }
          else {
            this.dialogService.infoPrompt('Ingen tekst/modtager valgt', 'Annulleret');
          }
        }
      }
    );
  }

  private voucherCombine(event: UiCommandEvent) {
    var msgId: number = this.getEventMsgId(event);
    var userId: number = this.selectedGrid.gridService.getFieldFilterValueNumber('modifiedBy');
    var cmd = { msgId: msgId, userId: userId } as BankTransactionVoucherCombineSelectedCommand;
    this.voucherCombineCommand(cmd);
  }

  private voucherCombineCommand(cmd: BankTransactionVoucherCombineSelectedCommand) {
    this.routeService.isLoading$.next(true);
    this.settlementClient.voucherCombineSelected(cmd).subscribe({
      next: (ok) => {
        if (ok) {
          this.selectedGrid.research();
          if (cmd.msgId) {
            this.voucherOpenFormMsgId(cmd.msgId)
          }
          else {
            this.selectedGrid.research();
          }
        }
        this.routeService.isLoading$.next(false);
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    });
  }

  private getEventAppGrid(event: UiCommandEvent) {
    var appGrid: CerGridComponent = null;
    if (event.appComponent && (event.appComponent instanceof CerGridComponent)) {
      appGrid = (<CerGridComponent>event.appComponent);
    };
    return appGrid;
  }

  private getEventMsgId(event: UiCommandEvent) {
    var msgId: number = null;
    if (!msgId) {
      var row = event.args.rowInfo?.rowData;
      if (!row) {
        var appGrid: CerGridComponent = this.getEventAppGrid(event);
        row = appGrid?.rowSelectedData();
      }
      if (!row?.sourceId) {
        var rows: any[] = this.selectedGrid.grid.getCurrentViewRecords();
        if (rows?.length > 0) {
          row = rows.find(t => t.sourceId != null);
        }
      }
      msgId = row?.sourceId;
    }
    return msgId;
  }

  private getEventBankTransactionId(event: UiCommandEvent) {
    var row = event.args.rowInfo?.rowData;
    if (!row || row.sourceId) {
      var appGrid: CerGridComponent = this.getEventAppGrid(event);
      row = appGrid?.rowSelectedData();
    }
    if (!row || row.sourceId) {
      var rows: any[] = this.selectedGrid.grid.getCurrentViewRecords();
      if (rows?.length > 0) {
        row = rows.find(t => t.sourceId == null);
      }
    }

    var bankTransactionId: number = null;
    if (row && !row.sourceId) {
      bankTransactionId = row.id;
    }
    return bankTransactionId;
  }


  private voucherOpenForm(event: UiCommandEvent, msgIdFixed: number = null) {
    var msgId: number = this.getEventMsgId(event);
    if (msgId) {
      this.voucherOpenFormMsgId(msgId);
    }
  }

  private voucherOpenFormMsgId(msgId: number) {
    if (msgId) {
      this.routeService.isLoading$.next(true);
      this.detailsClient.get(msgId).subscribe({
        next: (vm) => {
          this.openDetailsForm(vm);
          this.routeService.isLoading$.next(false);

        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          this.appDialogService.handleApiStatusMessage(error);
        }
      });
    }
  }

  private openDetailsForm(vm: VoucherMessageViewDetailsVm) {
    this.openVoucherDetails(this.dialog, vm, this.chatService);
  }

  public openVoucherDetails(dialog: MatDialog, vm: VoucherMessageViewDetailsVm, chatService: CerAppChatService) {
    this.voucherService.prepareDetailsVm(vm);
    if (vm.voucherMessageViewDto) {
      //this.subbase.manage(
      chatService.chats$.subscribe(chatsPosted => VoucherDataService.detailsVmRefreshChats(vm, this.chatService, chatsPosted));

      //));
      // this.voucherService.VoucherMessageAdjustDatesAsUTC(vm.voucherMessageViewDto);
      var config = <MatDialogConfig>{
        width: '98vw', height: '98vh', maxWidth: '98vw', disableClose: true,
        data: {
          dto: vm.voucherMessageViewDto,
          userIsAdmin: vm.userIsAdmin,
          editVoucher: true,
          editPosting: true,
          jumpToPostingOnVoucherComplete: false,
          attachmentsIsActive: true,
          attachmentSelectedIdxDefault: 0,
          detailsVmMap: null,
          showChat: true
        }
      };
      let dialogRef: MatDialogRef<VoucherDetailComponent> = dialog.open(VoucherDetailComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.needRefresh) {
          this.grid.research();
          this.selectedGrid.research();
        }
      });
    }

  }

  private voucherCreate(event: UiCommandEvent) {
    var cmd = { bankTransactionId: null, status: BankSettlementStatusEnum.Selected } as BankAccountTransactionVoucherCreateCommand;
    this.voucherCreateCmd(cmd, true);
  }

  private voucherCreateFromBank(event: UiCommandEvent) {
    var bankTransactionId = this.getEventBankTransactionId(event);
    if (bankTransactionId != null) {
      var cmd = { bankTransactionId: bankTransactionId, status: BankSettlementStatusEnum.Selected } as BankAccountTransactionVoucherCreateCommand;
      this.voucherCreateCmd(cmd, true);
    }
  }

  private voucherCreateCmd(cmd: BankAccountTransactionVoucherCreateCommand, refreshAllGrids: boolean) {
    this.routeService.isLoading$.next(true);
    this.transactionClient.voucherCreate(cmd).subscribe({
      next: (result) => {
        this.selectedGrid.research();
        if (refreshAllGrids) {
          this.grid.research();
          this.detailGrid.research();
        }
        this.routeService.isLoading$.next(false);
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    });
  }

  private syncBatchAll() {
    // Ikke aktiveret
    var cmd = { syncGenericSources: true } as BankAccountSourceSyncCommand;
    this.syncBatch(cmd);
  }

  private syncBatch(cmd: BankAccountSourceSyncCommand) {
    var snackBarRef = this.snackBar.open('Synkroniserer kilder', 'Vent venligst...', { duration: undefined });
    this.routeService.isLoading$.next(true);
    this.batchClient.syncBatch(cmd).subscribe({
      next: (result) => {
        snackBarRef.dismiss();
        this.snackBar.open(result + ' bevægelser hentet');
        this.grid.research();
        this.routeService.isLoading$.next(false);
      },
      error: (error) => {
        snackBarRef.dismiss();
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    });
  }

  private apiVoucherMessageStatusSet(msgId: number, toStatus: VoucherMessageStatusEnum) {
    if (msgId != null && toStatus != null) {
      var snackBarRef = this.snackBar.open('Sæt status', 'Vent venligst...', { duration: undefined });
      this.routeService.isLoading$.next(true);
      this.voucherMessageViewClient.setStatus(msgId, toStatus, false).subscribe({
        next: (messageDtoUpdated) => {
          snackBarRef.dismiss();
          this.grid.research();
        },
        error: (error) => {
          snackBarRef.dismiss();
          this.routeService.isLoading$.next(false);
          this.appDialogService.handleApiStatusMessage(error);
          }
      });

    }
  }
}