import { BankAccountSourceSyncCommand, BankTransactionSourceBatchClient } from '../../api';
import { FieldFormatEnum, FieldWidthEnum } from '../../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiCommand, UiKeyboardShortcut, UiCommandEvent } from '../../../cer/cer-grid/cer-grid-command.service';

export class BankAccountTransactionBatchFormAdapter extends CerAppFormModelAdapter {

  private uiCmdSyncBatch: UiCommand = { id: 'SyncBatch', text: 'Synkroniser kilder', tooltipText: 'Synkroniser transaktioner fra alle kilder (ALT+Y)', iconCss: 'e-menu-icon e-icons e-repeat', target: ".e-content", needSelection: false };
  private uiCmdSyncBatchShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSyncBatch, code: 'KeyS', shift: false, ctrl: false, alt: true };

  constructor() {
    super();

    this.viewMetadata = {
      name: 'bank-transaktion-indlæsning', dataApiName: 'BankAccountTransactionSourceBatch', text: 'Indlæsninger',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: true, baseFields: ['id','history']
    };

    this.fieldMetadata =
      [
      { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: "BankAccount", widthType: FieldWidthEnum.XXS },
      { name: 'source', text: 'Kilde', foreignKeyTableName: "BankAccountTransactionSource", widthType: FieldWidthEnum.XXS, filterValue: [2, 11, 12, 13], filterOperator: 'in', filterOnClient: true },
      { name: 'sourceId', text: 'Dokument', format: FieldFormatEnum.Integer, widthType: FieldWidthEnum.XXS, visible: false },
      { name: 'batchDate', text: 'Dato', format: FieldFormatEnum.DateShort },
      { name: 'name', text: 'Navn', visible: false },
      { name: 'startBalance', text: 'Startsaldo', format: FieldFormatEnum.Amount },
      { name: 'endBalance', text: 'Slutsaldo', format: FieldFormatEnum.Amount },
      { name: 'status', text: 'Status', foreignKeyTableName: "BankAccountTransactionStatus", widthType: FieldWidthEnum.XXS }
      ];

    this.toolbarCommands = [this.uiCmdSyncBatch];
    this.keyboardShortcuts = [this.uiCmdSyncBatchShortcut];

    this.detailViewMetadata = {
      name: 'bank-transaktion', dataApiName: 'BankAccountTransaction', text: 'Poster',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'sourceBatch', baseFields: ['id','history']
    };

    this.detailFieldMetadata =
      [
	  { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', visible: false, orderBy: 'Descending' },
      { name: 'sourceBatch', text: 'Batch', format: FieldFormatEnum.Integer, visible: false },
      { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: "BankAccount", widthType: FieldWidthEnum.S, visible: false },
      { name: 'source', text: 'Kilde', foreignKeyTableName: "BankAccountTransactionSource", visible: false },
      { name: 'bankTransId', text: 'Bank trans id', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'bankTransType', text: 'Bank trans type', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'transDate', text: 'Dato', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.S, orderBy: 'Descending' },
      { name: 'txt', text: 'Tekst', widthType: FieldWidthEnum.XXL },
      { name: 'authReference', text: "Nota Indløser", widthType: FieldWidthEnum.XXS },
      { name: 'bankCreditCard', text: "Kreditkort", widthType: FieldWidthEnum.XS, foreignKeyTableName: 'BankCreditCard', visible: false },
      { name: 'user', text: 'Medarbejder', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
      { name: 'amount', text: 'Beløb', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.L, footerType: 'sum' },
      { name: 'balance', text: 'Saldo', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.L },
      { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXS },
      { name: 'status', text: 'Status', foreignKeyTableName: "BankAccountTransactionStatus", widthType: FieldWidthEnum.XXS }
      ];

    this.rightPaneWidthPct = 60;
  }

  override onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case this.uiCmdSyncBatch.id:
        this.syncAll();
        break;
    }
  }

  override onDetailCommand(event: UiCommandEvent) {
  }

  private syncAll() {
    this.routeService.isLoading$.next(true);
    var snackBarRef = this.dialogService.snackBar('Synkroniserer kilder', 'Vent venligst...', undefined);
    //var = new BankTransactionSourceBatchClient());
    var cmd = <BankAccountSourceSyncCommand>{ syncGenericSources: true };
    var syncClient: BankTransactionSourceBatchClient = this.injector.get(BankTransactionSourceBatchClient);
    syncClient.syncBatch(cmd).subscribe(
      result => {
        snackBarRef?.dismiss();
        this.dialogService.snackBar(result + ' bevægelser hentet');
        this.grid.research();
        this.routeService.isLoading$.next(false);
      },
      error => {
        snackBarRef?.dismiss();
        this.routeService.isLoading$.next(false);
        this.appStateService.handleApiError(error);
        console.error(error)
      }
    );
  }
}
